<template>
	<div class="terms-and-conditions-container">
		<section id="main" class="container">
			<header>
				<h1>CLOUD PRACTICE INC. TERMS OF USE</h1>
				<p>Effective January 29, 2020</p>
			</header>
			<div class="box">
				<p>
					This is a legal agreement (“Agreement”) between you and Cloud Practice Inc., a registered British Columbian company who provides services via the Kii Health platform (“Kii Health”), and may be contacted at:
					<br>#302 - 31 Bastion Square,
					<br>Victoria BC, V8W 1J1.
				</p>
				<p>
					By checking “I agree” to the Terms of Use when registering for an account to use any of the Services, as defined below, either through the Kii Health website or app (herein jointly referred to as the “Application”),
					you acknowledge that you have read, understood and agree to comply with and be bound by the terms and conditions of this Agreement for as long as you continue to use the Services. If you do not agree with the Agreement,
					do not use this Application.
				</p>
				<p>
					By checking “I agree” to the Terms of Use when registering for an account to use any of the Services, as defined below, either through the Kii Health website or app (herein jointly referred to as the “Application”),
					you acknowledge that you have read, understood and agree to comply with and be bound by the terms and conditions of this Agreement for as long as you continue to use the Services. If you do not agree with the Agreement,
					do not use this Application.
				</p>
				<p>
					Kii Health is a platform accessed via web browser or a downloaded app that (1) allows patients to book appointments with their physician and manage their schedule;
					(2) enables patients to receive appointment reminders via email and/or text message; (3) provides the opportunity for Healthcare Providers to conduct virtual medical
					appointment with patients via video feed, audio and chat functionalities; (4) offers patients health monitoring tools such as triage and symptom checkers and permits
					upload of a user’s own health information; and (5) collects demographic and health information for use in primary healthcare and business analytics (herein referred to as the “Services”).
					Cloud Practice may offer additional services or revise any of the Services at its discretion, and this Agreement will apply to all services or revised Services. Cloud Practice reserves the
					right to discontinue offering any of the Services.
				</p>
				<p>
					The Application is intended for persons thirteen (13) years or older. Persons under the age of thirteen (13) should not access, use and/or browse the Application or the Services.
				</p>
				<h5>1. ACCEPTANCE OF THE AGREEMENT</h5>
				<p>
					You agree to the terms and conditions, without modification, outlined in this Agreement. This Agreement may be changed by us from time to time without specific notice to you, by updating this posting.
					This Agreement constitutes the entire and only agreement between us and you, and supersedes any prior agreements, representations, warranties and understandings with respect to the Application,
					the content or services provided by the Application, and the subject matter of this Agreement. By agreeing, Cloud Practice grants to you a limited, revocable, personal, non-sub-licensable, non-transferable,
					non-exclusive right to access and use the Application in accordance with this Agreement solely for personal reasons and not for resale or to provide services to third parties.
				</p>
				<p>
					By accepting this Agreement and during your any subsequent access and use of the Application, at all times you represent and warrant to Cloud Practice that you are (1) a resident of Canada;
					(2) that any information you provide at any time to Cloud Practice is true, accurate, and complete; (3) that you have all necessary rights to provide any information, data,
					materials or content to Cloud Practice for the purposes described in this Agreement; (4) that at all times during this Agreement, you shall not communicate with any Healthcare Provider that is
					not in compliance with any applicable local, domestic or foreign legislation; and (5) that you agree to hold any software and other confidential or proprietary information disclosed under this
					Agreement by Cloud Practice in strict confidence and not to use such information for any purpose except as expressly permitted hereunder.
				</p>
				<h5>2. USE OF THE APPLICATION</h5>
				<p>
					The functionality of the Application is to be used by patients to book medical appointments and receive appointment reminders via email and/or text message,
					for Healthcare Providers to conduct virtual medical appointment with patients via video feed, audio and chat functionalities, and for patients to monitor and upload health information.
					All functionality is provided on an “as is” basis without warranties of any kind. The functionality of the Application is not intended to take the place of standard medical advice.
					Using or browsing the Application and/or providing your personal or medical information while using our Services does not create a provider-patient relationship between you and Cloud Practice,
					and does not replace the services of a licensed health professional in your province. Except where Healthcare Providers are providing you with clinical services via video,
					audio or chat features under a provider-patient relationship, you agree that you shall not make any health or medical related decision(s) based on anything contained in the Application.
					IF YOU HAVE A MEDICAL EMERGENCY, PLEASE SEEK MEDICAL ATTENTION IMMEDIATELY BY PHONING YOUR EMERGENCY MEDICAL SYSTEM (9-1-1 IN MANY JURISDICTIONS) AND/OR BY GOING TO A HOSPITAL.
				</p>
				<p>
					You may not register for an account, use or access the Application if you are (or are engaged by) a competitor of Cloud Practice,
					except with Cloud Practice’s prior written consent. In addition, you may not register for an account, use or access the Application for purposes of monitoring its availability,
					performance or functionality, or for any other benchmarking or competitive purposes.
				</p>
				<h5>3. USER ACCOUNT</h5>
				<p>
					In order to create a user account, you must provide your real first and last name, an email address and create a password.
					You are solely responsible for maintaining the secrecy of your password. Your account is for your personal use only.
					You may not authorize others to use your account or transfer your account to any other person.
					You agree that Cloud Practice is not responsible for another party’s access to your account that results from misappropriation of your log-in details.
					You agree that you will notify Cloud Practice promptly of any unauthorized access or use of your account via the contact information provided below (Section: Contacting Cloud Practice).
				</p>
				<h5>4. ACTIVITIES WHEN ACCESSING OUR SERVICES</h5>
				<p>
					You are solely responsible for all use of your account and any activity performed through it. You are responsible for moderating the content you provide or display as a part of your use of the Services,
					and your interactions with Healthcare Providers when using our Services. You agree not to use the Application in any manner to harass, abuse, stalk,
					threaten, defame or otherwise infringe or violate the rights of any other party, and you further agree that you will not send false or misleading information via the Application.
					You will not advertise to or solicit any user or Healthcare Provider to buy or sell any products or services through the Application or its Services.
				</p>
				<h5>5. INTELLECTUAL PROPERTY AND OWNERSHIP</h5>
				<p>
					Cloud Practice exclusively owns and retains all right, title and interest in and to the Application and all software, materials, formats, interfaces, information, text, data,
					graphics, images, video, logos, icons, audio, content, computer code, proprietary and confidential information, and technology used by Cloud Practice or provided to you in connection
					with the Application and any compilation thereof. “Cloud Practice Inc.,” “Kii Health,” the Cloud Practice logo, the Kii Health logo, and other Cloud Practice logos and product and service names
					are or may be trademarks of Cloud Practice, and are protected under applicable copyrights, trademarks and other proprietary (including but not limited to intellectual property) Canadian laws.
					Cloud Practice permits you to view the contents, solely for your personal, non-commercial use. Images and videos on our Application must not be altered or digitally enhanced,
					and their use must be in accordance with Canada’s Copyright Act (R.S.C., 1985, c.C-42). Any other use or alteration of the material is strictly prohibited without our prior written permission.
					Cloud Practice will have a royalty-free, worldwide, transferable, sublicensable, irrevocable, perpetual license to use or incorporate into the Application any suggestions, enhancement requests,
					recommendations or other feedback provided by you, relating to the Application.
				</p>
				<h5>6. SYSTEM INTEGRITY</h5>
				<p>
					You may not use any device, software or routine to interfere or attempt to interfere with the proper working of the Application.
					You may not take any action, which imposes an unreasonable or disproportionately large load on Cloud Practice’s infrastructure.
					When using the Application, the communication of data may be transmitted over networks in which Cloud Practice cannot guarantee secure or reliable data transmission.
					As such, we assume no responsibility for the delay, failure, interruption or corruption of any data or other information transmitted in connection with this Application.
					From time to time, the Application may be unavailable for reasons within Cloud Practice’s control such as system maintenance or reasons outside of Cloud Practice’s control.
					When this occurs, Cloud Practice is not liable to you or other users for any interruptions in the use of our Services.
				</p>
				<h5>7. PROTECTION OF PRIVACY AND SECURITY</h5>
				<p>
					In addition to this Agreement, you must review and agree to our <a @click="routeToPrivacyPolicy()">Privacy Policy</a> before creating an account. As outlined in the Policy,
					protection of your Personal Information is a top priority for us, and as such, we will maintain administrative, physical, and technical safeguards at a level no less protective than those described in our Privacy Policy.
					In order to maintain the privacy and security of the Application, you must not disclose any security or privacy vulnerabilities to any person except Cloud Practice.
					Immediately upon discovering any issues that may be pose a threat to the privacy and security of the Application or data stored within the Application, you must notify us at <a href="mailto:privacy@cloudmd.ca">privacy@cloudmd.ca</a>
				</p>
				<h5>8. COMMUNICATIONS AND PRIVACY</h5>
				<p>
					In accordance with Canada’s Anti-Spam Law (S.C. 2010, c.23), we require your consent for your email address to be used to provide you with information for promotional purposes.
					At any time, you can opt-out of most email communication from us by clicking on the unsubscribe link at the bottom of our emails. We may still contact you for administrative purposes,
					for example to notify you of a change to the Services, change to this Terms of Use, or if there is a service interruption.
				</p>
				<h5>9. ERRORS AND CHANGES</h5>
				<p>
					Cloud Practice has made every effort to ensure the proper function of the Application; however, Cloud Practice is not responsible for any damages occurred while using the Application.
					We do not warrant that the Application will be error-free or that any defects will be corrected. We may make changes to the Application’s features, functionality, or other components at any time.
				</p>
				<h5>10. LIMITATION OF LIABILITY</h5>
				<p>
					TO THE EXTENT PERMITTED BY APPLICABLE LAW, CLOUD PRACTICE SHALL NOT BE LIABLE TO YOU OR TO ANY OTHER PARTY UNDER ANY CIRCUMSTANCES FOR ANY LOSS,
					DAMAGE OR LIABILITY OF ANY KIND ARISING OUT OF OR RELATING YOUR ACCESS OR USE, INABILITY TO USE OR RELIANCE UPON THE APPLICATION, ITS SERVICES OR ANY RELATED MATTER,
					INCLUDING, BUT NOT LIMITED TO, MONETARY, PHYSICAL, OR PSYCHOLOGICAL HARM, UNDER ANY THEORY, REGARDLESS OF ANY FAULT OR WRONGDOING BY OR ON BEHALF OF CLOUD PRACTICE AND EACH OF ITS AFFILIATES AND SUBSIDIARIES,
					LICENSORS, SUPPLIERS AND SERVICE PROVIDERS, AND EACH OF THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, CONTRACTORS, AGENTS, SHAREHOLDERS, DISTRIBUTORS AND REPRESENTATIVES, JOINTLY AND SEVERALLY.
					THIS LIMITATION OF LIABILITY IS APPLICABLE WHEN OTHER REMEDIES ARE NOT AVAILABLE, WHERE YOU ARE NOT ADEQUATELY COMPENSATED FOR THE LOSS, DAMAGE OR LIABILITY,
					AND IF CLOUD PRACTICE KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF ANY LOSS, DAMAGE OR LIABILITY OF ANY KIND OCCURRING.
				</p>
				<h5>11. INDEMNITY AND RELEASE</h5>
				<p>
					You agree to indemnify, defend and hold us and our affiliates and subsidiaries, licensors, suppliers and service providers, and each of their respective directors, officers, employees,
					contractors, agents, shareholders, distributors and representatives harmless from any liability, loss, claim and expense, including reasonable legal fees,
					related to your violation of this Agreement or use of the Application and/or Services. You also agree to release and hold harmless Cloud Practice from and against any and all liability,
					claims damages, actions, and costs, arising out of or in connection with your use, review or reproduction of the Application.
				</p>
				<h5>12. SEVERABILITY AND WAIVER</h5>
				<p>
					Unless as otherwise stated in the Agreement, should any provision of the Agreement be held invalid or unenforceable for any reason,
					it will not affect or make the remaining provisions in the Agreement unenforceable or invalid to the extent permitted by law.
					Any waiver or failure to enforce any provision of this Agreement on one occasion will not be deemed a waiver of any other provision or of such provision on any other occasion.
					All waivers must be in writing. Other than as expressly stated herein, the remedies provided herein are in addition to, and not exclusive of, any other remedies of a party at law or in equity.
				</p>
				<h5>13. DISCLAIMER OF WARRANTY</h5>
				<p>
					You assume all risk when using the Services, including but not limited to risks associated with your interactions with Healthcare Providers and your provision of and use of the information
					provided in the Application for health-related purposes. Cloud Practice makes no representations and disclaims all expressed and implied warranties and conditions of any kind,
					including without limitation, representations, warranties or conditions regarding accuracy, timeliness, completeness, non-infringement, merchantability or fitness for any particular purpose.
					Cloud Practice assumes no responsibility to the user or any third party for the consequences of any errors or faults in the Application.
				</p>
				<p>
					From time to time, Cloud Practice may offer “test” features within the Application.  Such features are offered only for research and user experimental purposes only,
					and may be modified or discontinued by Cloud Practice at any time. This Disclaimer of Warranty section applies to such features or tools.
				</p>
				<h5>14. OUR REMOVAL RIGHTS</h5>
				<p>
					If we believe that there is a violation of the Agreement that can simply be remedied by a user, whether that is the removal of information or certain actions,
					we may ask you to take direct action rather than intervene. However, we may be required to also engage in the situation and determine the appropriate action to be taken.
					If you are unable to act in accordance with our requested actions and we believe you are a credible risk of harm to us, other users, Healthcare Providers or third parties,
					we may be required to remove your rights to use the Application and our Services.
				</p>
				<h5>15. TERM AND TERMINATION</h5>
				<p>
					This Agreement will commence on the date you register for the Application or begin to use the Application and continues until terminated in accordance with the provisions of this Agreement.
					You may cease using the Application at any time. Cloud Practice may cease providing you the Application at any time without notice. As noted in s. 14 (Our Removal Rights),
					Cloud Practice may terminate this Agreement immediately, and at any time, if you violate your obligations under this Agreement. Upon the termination of this Agreement for any reason,
					(a) the license granted to Cloud Practice in respect of the personal information you have provided to Cloud Practice will survive for so long as Cloud Practice is required by law to retain such information;
					(b) Cloud Practice will no longer provide and you will no longer use the Application; and (c) Cloud Practice will be entitled to retain and use your personal information in accordance with the terms of this Agreement,
					Cloud Practice’s Privacy Policy, and subject always to Applicable Laws. The rights and duties of the parties under Sections, 5, 10, 11, 12, 13, and 15, will survive the termination or expiration of this Agreement.
				</p>
				<h5>16. DISPUTES</h5>
				<p>
					Except as expressly set out below, and unless applicable law requires otherwise, all disputes, controversies and claims arising under, out of,
					in connection with, or in relation to these Terms of Use, the Application, the Services or any related matter (each a “Dispute”) will be referred
					to and finally resolved by binding arbitration administered by the International Centre for Dispute Resolution (ICDR) Canada in accordance with ICDR’s Canadian Arbitration Rules.
					The arbitration will take place in Victoria, BC, Canada and will be conducted in the English language. One arbitrator will be assigned to the Dispute. If ICDR Canada is not operative,
					the arbitration will proceed under the governance of British Columbia’s Arbitration Act (RSBC 1996, c 55).
				</p>
				<p>
					Notwithstanding the foregoing, Cloud Practice may commence legal proceedings against you in the courts of any jurisdiction seeking injunctive relief or other
					legal remedies to uphold these Terms of Use or to protect Cloud Practice’s rights and interests. Unless applicable law requires otherwise, you have twelve (12)
					months after the Dispute arises against you by Cloud Practice to commence legal proceeding. After twelve (12) months, any and all legal proceedings by you regarding the Dispute will not be permitted.
					Any shorter time limit provided by applicable law remains unaffected.
				</p>
				<h5>17. GOVERNING LAW</h5>
				<p>
					This Agreement, the Application and all other related matters are governed by and will be construed in accordance with the laws of the Province of British Columbia,
					and any federal laws of Canada that may be applicable. Should situations arise where international laws may be applicable or there be conflict with other jurisdictional laws, the governing law(s) will be applied.
				</p>
				<h5>18. INUREMENT</h5>
				<p>
					All covenants, representatives, warranties and agreements contained in the Agreement will be binding upon and will inure to the benefit of both Cloud Practice and you and any respective successors,
					permitted assigns, legal representatives, heirs and trustees.
				</p>
				<h5>19. LANGUAGE</h5>
				<p>
					These Terms of Use and all related notices and other documents will be written in the English language. Subject to applicable law,
					any non-English translation of these Terms of Use provided by Cloud Practice will be upon request only and for your convenience.
					Should there be a conflict or inconsistency between the English version and a non-English version then the English version will take priority and govern.
				</p>
				<h5>20. CONTACTING CLOUD PRACTICE</h5>
				<p>
					Please feel free to contact us if you have any questions or additional comments regarding this Agreement. You may contact us at or at our mailing address below:
					<br>Attn: Cloud Practice Inc.
					<br>#302 - 31 Bastion Square
					<br>Victoria BC V8W1J1
					<br>Email: <a href="mailto:privacy@cloudmd.ca">privacy@cloudmd.ca</a>
				</p>
			</div>
		</section>
	</div>
</template>

<script>
	import router from "@/router/router";

	export default {
		name: "CloudMDTermsAndConditionsText",

		props: {
			inModalMode: Boolean,
		},

		data()
		{
			return {
				router,
			};
		},

		methods: {
			routeToPrivacyPolicy()
			{
				this.router.push({name: this.Route.Public.PrivacyPolicy});
				if (this.inModalMode)
				{
					modalController.dismiss();
				}
			},
		},
	};
</script>

<style scoped lang="scss">
	.terms-and-conditions-container {
		header {
			text-align: center;
		}

		a {
			color: var(--color-primary-darker);
			font-style: italic;
			cursor: pointer;

			&:hover {
				 text-decoration: underline;
				 color: var(--color-primary-base);
			 }
		}
	}
</style>
