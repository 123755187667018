import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-22f6b4e9"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = { class: "bottom-text" }
const _hoisted_3 = { class: "left-text" }
const _hoisted_4 = { class: "right-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["header-logo", {center: _ctx.center, 'full-width': _ctx.fullWidth}])
  }, [
    _createElementVNode("img", {
      class: "no-text",
      src: _ctx.logoSrc,
      alt: _ctx.logoAlt
    }, null, 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _renderSlot(_ctx.$slots, "left", {}, undefined, true)
      ]),
      _createElementVNode("span", _hoisted_4, [
        _renderSlot(_ctx.$slots, "right", {}, undefined, true)
      ])
    ])
  ], 2))
}